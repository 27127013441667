import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { UserListActions } from '../../../state/userListActions';
import { InviteUserForm } from './InviteUserForm/InviteUserForm';
import { StyledUserManagementDrawer } from './UserManagementDrawer.styles';
import * as UserListSelectors from 'app/modules/user-management/user-list/state/UserListSelectors';

export enum InviteUserTab {
  INVITE_USER = 'INVITE_USER',
}

export const UserManagementDrawer = (): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isUserManagementDrawerVisible = useSelector(UserListSelectors.selectUserManagementDrawerVisible);

  const hideUserManagementDrawer = (): void => {
    dispatch(UserListActions.hideUserManagementDrawer());
  };

  return (
    <StyledUserManagementDrawer
      className="user-management-panel"
      title={t('userList.userManagementPanel.titleAddUser')}
      size="small"
      open={isUserManagementDrawerVisible}
      onClose={hideUserManagementDrawer}
    >
      <Row gutter={24}>
        <Col span={24}>
          <div className="user-management-panel__description">
            {t('userList.userManagementPanel.descriptionAddUser')}
          </div>
        </Col>
      </Row>
      <div className="user-management-panel__body">
        <InviteUserForm />
      </div>
    </StyledUserManagementDrawer>
  );
};
