import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledUserManagementDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .user-management-panel__body {
    height: 100%;
  }

  .ant-drawer-title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    text-transform: uppercase;
  }

  .ant-tabs-content-holder {
    min-height: unset;
  }

  .invite-user-modal__description {
    ${(props): string => props.theme.fontStyles.p1.news};
    margin-bottom: 24px;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.semanticError};
  }

  .invite-user-modal__search-input-wrapper {
    border-left: 2px solid ${(props): string => props.theme.colors.darkGrey};
    border-right: 2px solid ${(props): string => props.theme.colors.darkGrey};
    padding: 0px 16px 8px;
  }

  .ant-select-dropdown-placement-topLeft .invite-user-modal__search-input-wrapper {
    border-top: 2px solid ${(props): string => props.theme.colors.darkGrey};
    padding: 8px 16px;
  }

  .ant-empty-small {
    margin: 0;
    padding: 8px;
  }

  .invite-user-modal__search-input {
    padding: 10px;

    &.ant-input-affix-wrapper-status-error {
      background: unset !important;
      border-color: ${(props): string => props.theme.colors.darkGrey} !important;

      .ant-input-prefix {
        color: unset;
      }
    }
  }

  .user-management-panel__description {
    font-size: ${(props): string => props.theme.fontSizes.p0};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    font-family: ${(props): string => props.theme.fonts.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.redesign.base};
    margin-bottom: ${(props): string => props.theme.spacing.sm};
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .user-management-panel__spacing {
    height: 24px;
  }
`;
